//region Imports
import { useHelper } from '@react-three/drei'

// React
import { Canvas }                                from '@react-three/fiber'
import { gsap }                                  from 'gsap'
import { ScrollTrigger }                         from 'gsap/ScrollTrigger'
// Controls
import { useControls }                           from 'leva'
import { Perf }                                  from 'r3f-perf'
import { Suspense, useEffect, useRef, useState } from 'react'
// THREE
import * as THREE                                from 'three'
import { PointLightHelper }                      from 'three'
// Styles
import './App.sass';
import Z_Camera                                  from './components/Z_Camera'

// Components
import Z_Model from './components/Z_Model'
import Z_Texts from './components/Z_Texts'
//endregion

gsap.registerPlugin( ScrollTrigger )

export default function App( {
	                             clickedMeshName,
	                             setClickedMeshName,
                             } ) {
	
	const showPerf = window.location.hash === '#perf'
	
	return ( <>
		<Canvas
				gl={ { toneMapping: THREE.LinearToneMapping } }
		>
			{ showPerf && <Perf position={ 'top-left' } /> }
			
			<Suspense>
				<Z_Canvas
						clickedMeshName={ clickedMeshName }
						setClickedMeshName={ setClickedMeshName }
				/>
			</Suspense>
		</Canvas>
	</> )
}

const Z_Canvas = ( {
	                   clickedMeshName,
	                   setClickedMeshName,
                   } ) => {
	//region Mobile
	// Screen size defined from the window width (0 : mobile, 1 : tablet, 2 : laptop)
	const [ screenSize, setScreenSize ] = useState( false )
	const getScreenSize                 = () => {
		if ( window.innerWidth < 1024 ) {
			// Mobile
			if ( window.innerWidth < 768 ) {
				return 0
			}
			// Tablet
			return 1
		}
		// Laptop
		return 2
	}
	useEffect(
			() => {
				setScreenSize( getScreenSize() )
			},
			[]
	)
	window.addEventListener(
			'resize',
			() => {
				setScreenSize( getScreenSize() )
			}
	)
	//endregion
	
	//region Scroll animation
	// Attenuation used to decrease the logo rotation on mouse move when the page is not scrolled (increases with scroll progress)
	const [ attenuation, setAttenuation ]   = useState( 0.1 )
	// Define if the hover handler should be enabled or not (based on the scroll progress)
	const [ hoverEnabled, setHoverEnabled ] = useState( false )
	
	// Debug properties used to change logo scale, position and rotation
	const [
		      {
			      ...logoGroupProps
		      }, setLogoGroupProps,
	      ] = useControls(
			'Logo',
			() => {
				return {
					showHelper:        false,
					scale:             100,
					position:          [
						0,
						0,
						0,
					],
					rotation:          [
						Math.PI / 2,
						0,
						Math.PI,
					],
					rotationAnimation: [
						0,
						0,
						0,
					],
				}
			},
			{ collapsed: true }
	)
	//endregion
	
	// region Debug properties used to change camera position and rotation
	const [ { ...cameraProps }, setCameraProps ] = useControls(
			'Camera',
			() => {
				return {
					near:       .1,
					position:   [
						0,
						0,
						2,
					],
					rotation:   [
						0,
						0,
						0,
					],
					showHelper: window.debugActive,
				}
			},
			{ collapsed: true }
	)
	
	// TODO: Remove when building before deploying
	useEffect(
			() => {
				gsap.timeline( {
					               scrollTrigger: {
						               trigger: '.threejs-container',
						               start:   'top top',
						               end:     'bottom bottom',
						               scrub:   true,
						
						               pin:        true,
						               pinSpacing: false,
						
						               onUpdate: self => {
							               setAttenuation( Math.min(
									               self.progress + .1,
									               1
							               ) )
							
							               //region Move camera backward
							               const min = 2
							               const max = 10
							
							               const current = self.progress * ( max - min ) + min
							               setCameraProps( {
								                               position: [
									                               0,
									                               0,
									                               current,
								                               ],
							                               } )
							               //endregion
							
							               //region Logo rotation
							               const startRotation = Math.PI
							               const endRotation   = 0
							
							               const currentRotation = self.progress
							                                       * ( endRotation
							                                           - startRotation )
							                                       + startRotation
							               setLogoGroupProps( {
								                                  rotation: [
									                                  logoGroupProps.rotation[ 0 ],
									                                  logoGroupProps.rotation[ 1 ],
									                                  currentRotation,
								                                  ],
							                                  } )
							               //endregion
							
							               //region Hover enabled ?
							               setHoverEnabled( self.progress > .8 )
							               //endregion
						               },
					               },
				               } )
			},
			[]
	)
	
	//region Window global functions to update the values from GSAP
	useEffect(
			() => {
				window.setAttenuation = setAttenuation
				window.setCameraProps = setCameraProps
				
				window.logoGroupProps    = logoGroupProps
				window.setLogoGroupProps = setLogoGroupProps
				
				window.setHoverEnabled = setHoverEnabled
				
				if ( window.ziben_loaded !== undefined ) {
					window.ziben_loaded()
				}
			},
			[]
	)
	//endregion
	//endregion
	
	//region Lights
	const lightRightProps = useControls(
			'Light right',
			{
				position:   [
					10,
					0,
					0,
				],
				intensity:  5,
				showHelper: false,
				color:      'white',
			},
			{ collapsed: true }
	)
	const lightLeftProps  = useControls(
			'Light left',
			{
				position:   [
					-10,
					0,
					0,
				],
				intensity:  5,
				showHelper: false,
				color:      'white',
			},
			{ collapsed: true }
	)
	
	const light_right = useRef()
	useHelper(
			lightRightProps.showHelper && light_right,
			PointLightHelper
	)
	const light_left = useRef()
	useHelper(
			lightLeftProps.showHelper && light_left,
			PointLightHelper
	)
	//endregion
	
	const { showText } = useControls(
			'Show text',
			{
				showText: true
			}
	)
	
	return ( <>
		{/*region Add some lights*/ }
		<ambientLight intensity={ 1 } />
		<pointLight ref={ light_right } { ...lightRightProps } />
		<pointLight ref={ light_left } { ...lightLeftProps } />
		
		{/* <pointLight color={ 'white' } */ }
		{/*             position={ [ */ }
		{/* 	            0, */ }
		{/* 	            3, */ }
		{/* 	            -2, */ }
		{/*             ] } */ }
		{/*             intensity={ 5 } */ }
		{/* /> */ }
		{/* <pointLight color={ 'white' } */ }
		{/*             position={ [ */ }
		{/* 	            2, */ }
		{/* 	            4, */ }
		{/* 	            4, */ }
		{/*             ] } */ }
		{/* /> */ }
		
		{/* <pointLight color={ 'white' } */ }
		{/*             position={ [ */ }
		{/* 	            1, */ }
		{/* 	            3, */ }
		{/* 	            2, */ }
		{/*             ] } */ }
		{/* /> */ }
		{/*endregion*/ }
		
		<group
				scale={ screenSize === 0 ? .5 : screenSize === 1 ? .9 : 1 }
		>
			{/*Model*/ }
			<Z_Model
					logoGroupProps={ logoGroupProps }
					clickedMeshName={ clickedMeshName }
					setClickedMeshName={ setClickedMeshName }
					hoverEnabled={ hoverEnabled }
					attenuation={ attenuation }
			/>
			
			{/*Texts*/ }
			{ showText &&
			  <Z_Texts
					  scale={ screenSize === 0 ? .3 : screenSize === 1 ? .5 : 1 }
					  hoverEnabled={ hoverEnabled }
					  clickedMeshName={ clickedMeshName }
			  />
			}
		</group>
		
		{/*Camera*/ }
		<Z_Camera cameraProps={ cameraProps } />
	</> )
}
