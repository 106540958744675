import React, { useState } from 'react';
import ReactDOM            from 'react-dom/client';
import './index.css';
import App                 from './App';
import reportWebVitals     from './reportWebVitals';
import { Leva }            from 'leva'

const root = ReactDOM.createRoot( document.getElementById( 'threejs-logo' ) );
root.render(
		<React.StrictMode>
			<Z_App />
		</React.StrictMode>,
);

const debugActive  = window.location.hash === '#debug'
window.debugActive = debugActive

function Z_App() {
	const [ clickedMeshName, setClickedMeshName ]   = useState( 'Top_Right' )
	window.clickedMeshName                          = clickedMeshName
	window.setClickedMeshName                       = setClickedMeshName
	
	return (
			<>
				<Leva collapsed={ !debugActive }
				      hidden={ false } />
				<div className="threejs-container">
					<App
					     clickedMeshName={ clickedMeshName }
					     setClickedMeshName={ setClickedMeshName }
					/>
				</div>
			</>
	)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
