import { animated, useSpring } from '@react-spring/three'
import { Plane }               from '@react-three/drei'
import { useControls }         from 'leva'
import { useEffect, useState } from 'react'
import useUpdateEffect         from '../hooks/useUpdateEffect'
import Z_Text                  from './Z_Text'

const Z_Texts = ( {
	                  scale,
	                  hoverEnabled,
	                  clickedMeshName,
                  } ) => {
	
	//region Text elements
	const [ texts ] = useState( [
		                            {
			                            node: 'Top_Right',
			                            name: 'text_blockchain',
			                            text: 'BLOCKCHAIN',
		                            },
		                            {
			                            node: 'Top_Left',
			                            name: 'text_nft',
			                            text: 'NFT',
		                            },
		                            {
			                            node: 'Bottom_Right',
			                            name: 'text_metaverse',
			                            text: 'METAVERSE',
		                            },
		                            {
			                            node: 'Bottom_Left',
			                            name: 'text_crypto',
			                            text: 'CRYPTO',
		                            },
	                            ] )
	//endregion
	
	//region Debug properties
	// Text style
	const { textStyle } = useControls(
			'Text style',
			{
				textStyle: {
					options: {
						None:   'None',
						Text:   'Text',
						Text3D: 'Text3D',
					}
				}
			}
	)
	
	// Text properties
	const textProps = useControls(
			`Texts`,
			{
				curveSegments:  8,
				bevelEnabled:   true,
				bevelSize:      {
					value: 0,
					min:   0,
					max:   1,
					step:  .01,
				},
				bevelThickness: {
					value: 0,
					min:   0,
					max:   1,
					step:  .01,
				},
				height:         {
					value: 0,
					min:   0,
					max:   1,
					step:  .1,
				},
				lineHeight:     1,
				letterSpacing:  {
					value: -.06,
					min:   -1,
					max:   1,
					step:  .01,
				},
				opacity:        1,
				size:           {
					value: 4,
					min:   0,
					max:   20,
					step:  1,
				},
				font:           'https://bary.admin-ziben.fr/ThreeJS_Files/Inter_Bold.json',
			},
			{ collapsed: true }
	)
	
	// Plane in front of text properties
	const
			{
				...planeProps
			}
					= useControls(
					'Plane in front of text',
					{
						args: [
							40,
							textProps.size * 1.1,
						],
					}
			)
	
	const [ planeAnimation, setPlaneAnimation ] = useSpring( () => ( {
		scale:    0,
		position: [
			0,
			-textProps.size - .5,
			1,
		],
		config:   { duration: 100 }
	} ) )
	//endregion
	
	// Plane color
	const [ planeColor, setPlaneColor ] = useState( '#121212' )
	useEffect(
			() => {
				// Method used in Webflow to define the place color on scroll (when changing section 4 background color)
				window.setPlaneColor = setPlaneColor
			},
			[]
	)
	
	useUpdateEffect(
			() => {
				if ( hoverEnabled ) {
					setPlaneAnimation.start( { to: { scale: 1 } } )
				} else {
					setPlaneAnimation.start( { to: { scale: 1 } } )
					setPlaneAnimation.start( {
						                         to:    { scale: 0 },
						                         delay: 2000,
					                         } )
				}
			},
			[ hoverEnabled ]
	)
	
	useUpdateEffect(
			() => {
				setPlaneAnimation.start( { to: { scale: 1 } } )
				setPlaneAnimation.start( {
					                         to:    { scale: 0 },
					                         delay: 2000,
				                         } )
			},
			[ clickedMeshName ]
	)
	
	return (
			<group
					position={ [
						0,
						0,
						-20,
					] }
					scale={ scale }
			>
				{/*Texts*/ }
				{ textStyle !== 'None' &&
				  <>
					  {
						  texts.map( text => {
							  return (
									  <Z_Text
											  text={ text }
											  key={ text.name }
											  hoverEnabled={ hoverEnabled }
											  selected={ clickedMeshName === text.node }
											  textProps={ textProps }
											  textStyle={ textStyle }
									  />
							  )
						  } )
					  }
					
					  {/*Plane in front of text*/ }
					  <animated.group  { ...planeAnimation }>
						  <Plane { ...planeProps }>
							  <meshBasicMaterial color={ planeColor } />
						  </Plane>
					  </animated.group>
				  </>
				}
			</group>
	)
}

export default Z_Texts
