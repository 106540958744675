import { PerspectiveCamera } from '@react-three/drei'

const Z_Camera = ( {
	                   cameraProps,
                   } ) => {
	return (
			<PerspectiveCamera
					makeDefault={ true }
					{ ...cameraProps }
			/>
	)
}

export default Z_Camera
