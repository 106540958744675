import { animated, useSpring }  from '@react-spring/three'
import { Center, Text, Text3D } from '@react-three/drei'
import { useState }             from 'react'
import useUpdateEffect          from '../hooks/useUpdateEffect'

const Z_Text = ( {
	                 text,
	                 hoverEnabled,
	                 selected,
	                 textProps,
	                 textStyle
                 } ) => {
	const [ groupAnimation, setGroupAnimation ] = useSpring( () => ( {
		                                                         position: [
			                                                         0,
			                                                         -4.5,
			                                                         0,
		                                                         ],
		                                                         config:   { clamp: true },
	                                                         } ),
	)
	const [ shown, setShown ]                   = useState( false )
	
	const positionHidden = [
		0,
		-4.5,
		0,
	]
	const positionShown  = [
		0,
		0,
		0,
	]
	
	// Show text changed
	useUpdateEffect(
			() => {
				if ( !hoverEnabled ) {
					// If it was selected
					if ( selected ) {
						// Hide
						setGroupAnimation.start( {
							                         to: { position: positionHidden },
						                         } )
						setTimeout(
								() => {
									setShown( false )
								},
								500
						)
					}
				} else {
					if ( selected ) {
						// Show
						setShown( true )
						setGroupAnimation.start( {
							                         to: { position: positionShown },
						                         } )
					}
				}
			},
			[ hoverEnabled ]
	)
	
	// Selected changed
	useUpdateEffect(
			() => {
				// Only if we want to show the text
				if ( hoverEnabled ) {
					// If it was selected
					if ( !selected ) {
						// Hide
						setGroupAnimation.start( {
							                         to: { position: positionHidden },
						                         } )
						
						setTimeout(
								() => {
									setShown( false )
								},
								500
						)
					} else {
						// Show
						setShown( true )
						setGroupAnimation.start( {
							                         to:    {
								                         position: positionShown,
							                         },
							                         delay: 500,
						                         } )
					}
				}
			},
			[ selected ]
	)
	
	return (
			<>
				{
						shown &&
						<animated.group
								key={ text.node }
								position={ groupAnimation.position }
						>
							{ textStyle === 'Text' &&
							  <Text
									  name={ text.name }
									  { ...textProps }
									  letterSpacing={0}
									  scale={50}
							  >
								  { text.text }
								  <meshBasicMaterial color={ 'white' } />
							  </Text>
							}
							{ textStyle === 'Text3D' &&
							  <Center>
								  <Text3D
										  name={ text.name }
										  { ...textProps }
								  >
									  { text.text }
									  <meshBasicMaterial color={ 'white' } />
								  </Text3D>
							  </Center>
							}
						</animated.group>
				}
			</>
	)
}

export default Z_Text
