import { useState }    from 'react'
import { useControls } from 'leva'
import useUpdateEffect from '../hooks/useUpdateEffect'

const Z_Logo_Mesh = ( {
	                      node,
	                      name,
	                      handleLogoEnter,
	                      topRightClicked,
	                      clickedMeshName,
	                      hoverEnabled,
                      } ) => {
	
	const [ displayHover, setDisplayHover ] = useState( clickedMeshName === name )
	
	//region Glass material
	// The default properties used by the material when the hoverEnabled is false (begining of the GSAP SCROLLTRIGGER) or when the mesh is not selected and the top right mesh (Blockchain) is not selected neither
	const defaultData         = {
		thickness:          .1,
		color:              'white',
		roughness:          0,
		transmission:       .998,
		clearcoat:          1,
		clearcoatRoughness: .2,
		ior:                1.05,
		envMapIntensity:    1,
		
		emissive:          'black',
		emissiveIntensity: 1,
		
		sheen:      0,
		sheenColor: 'black',
		
		specularIntensity: 1,
		specularColor:     'black',
	}
	// The material properties used when the selected mesh is the top right one (Blockchain)
	const topRightEnteredData = {
		thickness:       defaultData.thickness,
		color:           '#CBFF00',
		roughness:       defaultData.roughness,
		transmission:    defaultData.transmission,
		clearcoat:       defaultData.clearcoat,
		envMapIntensity: 0,
		
		ior: defaultData.ior,
		
		emissive:          '#CBFF00',
		emissiveIntensity: .05,
		
		sheen:      .3,
		sheenColor: 'black',
		
		specularIntensity: defaultData.specularIntensity,
		specularColor:     defaultData.specularColor,
	}
	
	//region Material properties used in the debug panel
	const materialPropsData             = {
		thickness:          {
			value: defaultData.thickness,
			min:   0,
			max:   1,
			step:  .001,
		},
		roughness:          {
			value: defaultData.roughness,
			min:   0,
			max:   1,
			step:  0.1,
		},
		clearcoat:          {
			value: defaultData.clearcoat,
			min:   0,
			max:   1,
			step:  0.1,
		},
		clearcoatRoughness: {
			value: defaultData.clearcoatRoughness,
			min:   0,
			max:   1,
			step:  0.1,
		},
		transmission:       {
			value: defaultData.transmission,
			min:   0.2,
			max:   1,
			step:  0.01,
		},
		ior:                {
			value: defaultData.ior,
			min:   1,
			max:   2.3,
			step:  0.05,
		},
		envMapIntensity:    {
			value: defaultData.envMapIntensity,
			min:   0,
			max:   100,
			step:  1,
		},
		color:              defaultData.color,
		
		emissive:          defaultData.emissive,
		emissiveIntensity: {
			value: defaultData.emissiveIntensity,
			min:   0,
			max:   1,
		},
		
		sheen:      {
			value: defaultData.sheen,
			min:   0,
			max:   1,
		},
		sheenColor: defaultData.sheenColor,
		
		specularIntensity: {
			value: defaultData.specularIntensity,
			min:   0,
			max:   1,
		},
		specularColor:     defaultData.specularColor,
	}
	const [ { ...materialProps }, set ] = useControls(
			`Material ${ name }`,
			() => materialPropsData,
			{ collapsed: true },
	)
	//endregion
	//endregion
	
	//region Handle click event
	function changeMaterialFromClickState() {
		if ( clickedMeshName === name ) {
			setDisplayHover( true )
		} else {
			setDisplayHover( false )
			if ( topRightClicked ) {
				set( { ...topRightEnteredData } )
			} else {
				set( { ...defaultData } )
			}
		}
	}
	
	// Method called everytime the "clicked mesh name" or the "top right clicked" value change
	useUpdateEffect( () => {
		if ( hoverEnabled ) {
			changeMaterialFromClickState()
		}
	}, [
		                 topRightClicked,
		                 clickedMeshName,
	                 ] )
	
	// Method called everytime the "hover enabled" value changes
	useUpdateEffect( () => {
		// If the hover is enabled -> we can display different material on the selected mesh
		if ( hoverEnabled ) {
			changeMaterialFromClickState()
		}
		// Otherwise, we have to display the default material
		else {
			set( { ...defaultData } )
		}
	}, [ hoverEnabled ] )
	
	//endregion
	
	return (
			<mesh geometry={ node.geometry }
					//region Handle events
                  onPointerEnter={ () => {
	                  // If the hover is enabled -> we change mesh color based on the selected mesh
	                  if ( hoverEnabled ) {
		                  // Parent handle
		                  handleLogoEnter( name )
		
		                  // Change active slider controller
		                  if ( window.setActiveSliderController !== undefined ) {
			                  window.setActiveSliderController( name )
		                  }
	                  }
                  } }
					//endregion
			>
				{/**
				 If the hover is enabled and the mesh is selected
				    -> display selected material (with bary color)
				 Otherwise,
				    -> display a physical material with some props :
				        - If the top right mesh is selected (Blockchain) -> topRightEnteredData props
				        - Otherwise, the defaultData props
				 */ }
				{ hoverEnabled && displayHover
				  ? <meshBasicMaterial color={ '#CBFF00' } />
				  : <meshPhysicalMaterial
						  { ...materialProps }
				  />
				}
			</mesh>
	)
}

export default Z_Logo_Mesh
