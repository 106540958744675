import { useGLTF }                     from '@react-three/drei'
import { useFrame }                    from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import * as THREE                      from 'three'
import useUpdateEffect                 from '../hooks/useUpdateEffect'
import Z_Logo_Mesh                     from './Z_Logo_Mesh'

const Z_Model = ( {
	                  logoGroupProps,
	                  clickedMeshName,
	                  setClickedMeshName,
	                  hoverEnabled,
	                  attenuation
                  } ) => {
	//region References
	const groupMouseMoveRef = useRef()
	//endregion
	
	// Defines if the top right mesh is clicked (Blockchain)
	const [ topRightClicked, setTopRightClicked ] = useState( true )
	
	// GLTF - Load GLB file to import logo meshes
	const {
		      nodes,
	      } = useGLTF( 'https://bary.admin-ziben.fr/ThreeJS_Files/logo_small_baked.glb' )
	
	// Handle
	const handleLogoEnter = ( name ) => {
		// If the entered node is the top right one
		setTopRightClicked( name === 'Top_Right' )
		
		// Show text
		setClickedMeshName( name )
	}
	//endregion
	
	// Function called every time the clickedMeshName value changes
	useUpdateEffect(
			() => {
				// Define if the clicked element is "Blockchain" (the top right element)
				setTopRightClicked( clickedMeshName === 'Top_Right' )
			},
			[ clickedMeshName ]
	)
	
	useEffect(() => {
		console.log(attenuation)
	}, [attenuation])
	
	//region Mouse move animation
	// Attenuation used to decrease the logo rotation on mouse move when the page is not scrolled (increases with scroll progress)
	const [ mouseMoveActivated, setMouseMoveActivated ] = useState( true )
	window.setMouseMoveActivated                        = setMouseMoveActivated
	
	//region Frame animation (mouse move)
	useFrame( ( state, delta ) => {
		if ( mouseMoveActivated ) {
			//region Get values
			const current_rotation = new THREE.Vector3()
			current_rotation.copy( groupMouseMoveRef.current.rotation )
			const mouse_position = {
				x: state.mouse.x,
				y: state.mouse.y,
			}
			//endregion
			
			//region Set default and max values
			const max_values       = {
				x: Math.PI / 8,
				z: Math.PI / 8,
			}
			const default_rotation = {
				x: 0,
				z: 0,
			}
			//endregion
			
			//region Calculate new position from mouse position
			const current_values = {
				x: default_rotation.x + ( max_values.x * mouse_position.y * attenuation ),
				z: default_rotation.z + ( max_values.z * mouse_position.x * attenuation ),
			}
			//endregion
			
			//region Set smoothed camera position
			current_rotation.lerp(
					current_values,
					delta * 4
			)
			
			groupMouseMoveRef.current.rotation.x = current_rotation.x
			groupMouseMoveRef.current.rotation.z = current_rotation.z
			//endregion
		}
	} )
	//endregion
	//endregion
	
	return (
			/*Logo group props is used to define the logo rotation based on the scroll position (GSAP SCROLL TRIGGER)*/
			<group
					{ ...logoGroupProps }
			>
				{/*Mouse move props is used to define rotate the logo based on the mouse position on the screen*/ }
				<group
						name={ 'mouse_move_animation' }
						rotation={[0,0,0]}
						ref={ groupMouseMoveRef }
				>
					<group
							rotation={ [
								0,
								-Math.PI / 2,
								0,
							] }
					>
						{/*region All the logo meshes*/ }
						<Z_Logo_Mesh
								node={ nodes.Top_Left }
								name={ 'Top_Left' }
								handleLogoEnter={ handleLogoEnter }
								topRightClicked={ topRightClicked }
								clickedMeshName={ clickedMeshName }
								hoverEnabled={ hoverEnabled }
						/>
						<Z_Logo_Mesh
								node={ nodes.Top_Right }
								name={ 'Top_Right' }
								handleLogoEnter={ handleLogoEnter }
								topRightClicked={ topRightClicked }
								clickedMeshName={ clickedMeshName }
								hoverEnabled={ hoverEnabled }
						/>
						<Z_Logo_Mesh
								node={ nodes.Bottom_Left }
								name={ 'Bottom_Left' }
								handleLogoEnter={ handleLogoEnter }
								topRightClicked={ topRightClicked }
								clickedMeshName={ clickedMeshName }
								hoverEnabled={ hoverEnabled }
						/>
						<Z_Logo_Mesh
								node={ nodes.Bottom_Right }
								name={ 'Bottom_Right' }
								handleLogoEnter={ handleLogoEnter }
								topRightClicked={ topRightClicked }
								clickedMeshName={ clickedMeshName }
								hoverEnabled={ hoverEnabled }
						/>
						{/*endregion*/ }
					</group>
				</group>
			</group>
	)
}

export default Z_Model
